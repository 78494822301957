import { css } from "@emotion/react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Fade from "react-reveal/Fade"
import Buttons from "../components/common/button"
import CTA from "../components/common/CTA"
import FeatureCards from "../components/common/Feature-card/FeatureCards"
import Frame from "../components/common/frame"
import TimelineCarousel from "../components/common/Timeline/TimelineCarousel"
import DoubleNavbar from "../components/DoubleNavbar.js"
import "../styles/pages/about-us-2025.scss"
import { BannerHeader } from "./index.js"
const AboutUsNew2025 = ({ data }) => {
  const { title, newField, ctaBoxes, seo } = data.SuperOps.pages[1]
  const { heroSection, pageImages } = data.SuperOps.pages[0]
  const hero = newField[0]
  const detail = newField[1]
  const impact = newField[2]
  const founders = newField[3]
  const timelineData = newField[4]
  const investor = newField[5]
  const banner = newField[6]
  const press = data.SuperOps.peopleList

  const alteredpress = {
    links: press.map(item => item.linkedInUrl),
    image: press.map(item => ({ url: item.thumbnail.url })),
    cardHeading: press.map(item => item.title),
    text: press.map(item => item.name),
  }
  const [imageSrc, setImageSrc] = useState(founders.image[0].url)

  useEffect(() => {
    const updateImageSrc = () => {
      if (window.innerWidth <= 768) {
        setImageSrc(founders.image[1].url)
      } else {
        setImageSrc(founders.image[0].url)
      }
    }

    updateImageSrc()
    window.addEventListener("resize", updateImageSrc)

    return () => window.removeEventListener("resize", updateImageSrc)
  }, [founders.image])

  return (
    <div className="about-page-2025">
      <Frame bigFooter seo={seo}>
        <DoubleNavbar navType="double-nav" />

        {/* <section className="hero-section  prelative">
          <Container className="Layout-container">
            <p className="tag fw-700 super-pink about-head down-to-up-1">
              {hero.tag}
            </p>
            <div className="about-title-wrapper">
              <h1 className="about-title font-zodiak fw-700 ">
                {parse(hero.heading.html)}
              </h1>
            </div>
          </Container>
        </section> */}

        <section className="hero-section-old  prelative">
          <div className="black-bg"></div>
          <Container className="Layout-container prelative">
            <Col lg="7" className="mx-auto pdg-100 text-center mb64">
              <p className="tag fw-700 super-pink about-head down-to-up-1">
                {hero.tag}
              </p>
              <div className="about-title-wrapper">
                <h1 className="about-title font-zodiak fw-700 ">
                  {parse(hero.heading.html)}
                </h1>
              </div>
              <p className="about-desc mx-auto down-to-up-3">
                {heroSection.heading?.text}
              </p>
            </Col>
            <div className={`prelative  groupAboutImg mx-auto down-to-up-4`}>
              <Col
                lg={12}
                className="d-flex flex-wrap justify-content-center g-20 d-dsk mx-auto prelative"
              >
                <div className="d-flex flex-column left-img-grp position-absolute d-dsk">
                  {pageImages?.slice(0, 2).map((img, i) => (
                    <div className={`w-100`}>
                      <img src={img?.url} />
                    </div>
                  ))}
                </div>
                {pageImages?.slice(4, 8).map((img, i) => (
                  <div>
                    <img src={img?.url} className="w-100" />
                  </div>
                ))}
                <div className="d-flex flex-column right-img-grp position-absolute d-dsk">
                  {pageImages?.slice(2, 4).map((img, i) => (
                    <div className={`w-100`}>
                      <img src={img?.url} />
                    </div>
                  ))}
                </div>
              </Col>
            </div>
          </Container>

          <div className="d-mob prelative">
            <img src={pageImages[13].url} className="w-100" />
          </div>
        </section>

        <section className="detail-section prelative">
          <Container className="Layout-container">
            <Row className="d-flex justify-content-center">
              <Col lg={4} className="left">
                <Fade bottom duration={1000}>
                  <div className="heading ">
                    {parse(detail.blockContent.html)}
                  </div>
                </Fade>
              </Col>
              <Col lg={8} className="right">
                <Fade bottom duration={1000}>
                  <div className="description">
                    {parse(detail.blockLink.html)}
                  </div>
                </Fade>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="impact-section prelative">
          <Container className="Layout-container">
            {parse(impact.content2[0].html)}
            <div className="d-flex flex-wrap justify-content-center box-wrapper">
              {impact.heading2.map((heading2, index) => (
                <div key={heading2 + index} className="impact-card">
                  <Fade
                    bottom
                    duration={500}
                    delay={index * 20}
                    className="box-fade"
                  >
                    <div
                      className="box"
                      key={index}
                      css={css`
                        background: ${impact.text[index]};
                      `}
                    >
                      <h3 className="box-head">{heading2}</h3>
                      <p className="box-content">{impact.title[index]}</p>
                    </div>
                  </Fade>
                </div>
              ))}
            </div>
          </Container>
        </section>

        <section className="founders-section prelative">
          <Container className="Layout-container">
            <div className="img-wrapper">
              <img src={imageSrc} alt={founders.cardName} />
            </div>
            <Row>
              <Col lg={2}></Col>
              <Col lg={10} className="content-wrapper">
                <div className="heading-wrapper">
                  <h2 className="heading">{founders.cardName}</h2>
                </div>
                <div className="black-box">
                  <div className="text-wrap">
                    {parse(founders.content2[0].html)}
                    {/* <p className="p-188">{parse(founders.content2[1].html)}</p>
                    <p className="p-118">{parse(founders.content2[2].html)}</p> */}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="timeline-section prelative">
          <Fade duration={1000} delay={700}>
            <Container className="Layout-container">
              <TimelineCarousel timelineData={timelineData} />
            </Container>
          </Fade>
        </section>

        <section className="investor-section prelative">
          <Fade bottom duration={1000} delay={700}>
            <Container className="Layout-container">
              <div className="heading-wrapper">
                <h2 className="heading">{investor.cardName}</h2>
              </div>
              <div className="investor-name-wrapper">
                <div className="bckg-blur"></div>
                {investor.heading2.map((heading2, index) => (
                  <Fade bottom duration={500} delay={100}>
                    <div className="investor">
                      <div className="img-wrapper">
                        <img
                          src={investor.image[index].url}
                          alt={investor.heading2[index]}
                        />
                      </div>
                    </div>
                  </Fade>
                ))}
              </div>
            </Container>
          </Fade>
        </section>

        <section className="banner-section prelative">
          <Container>
            <BannerHeader
              banners={banner.blockImage}
              title={banner.blockContent.html}
              buttonText={banner.linkName}
              buttonLink={banner.linkSlug}
            />
          </Container>
        </section>

        <section className="press-section prelative">
          <Container className="Layout-container">
            <Row className="d-flex justify-content-center">
              <Col lg={3} className="link-wrapper">
                <div className="heading-wrapper">
                  <h2 className="heading">In the Press</h2>
                </div>

                <Buttons
                  theme="primary-new down-to-up-4"
                  link={"/press-room"}
                  text={"READ MORE STORIES"}
                />
              </Col>
              <Col lg={9} className="content-wrapper">
                <FeatureCards
                  title={alteredpress?.cardHeading}
                  image={alteredpress?.image}
                  linkName={alteredpress?.text}
                  link={alteredpress?.links}
                  cardsPerRow={3}
                  showBgColorImgOnHover
                />
              </Col>
            </Row>
          </Container>
        </section>

        <section className="cta-black-red-stripes">
          <CTA
            data={[ctaBoxes[0]]}
            containerClass
            lgLeft={6}
            lgRight={4}
            newButton
            isSecondaryBtn
            newDesign
          />
        </section>
      </Frame>
    </div>
  )
}

export default AboutUsNew2025

export const query = graphql`
  query {
    SuperOps {
      pages(where: { title_in: ["AboutUs2025", "AboutUsNew"] }) {
        title
        heroSection {
          tag
          tagLink
          heading {
            text
          }
          content {
            text
          }
          heroExcerptList
          formCtaText {
            text
          }
          primaryButtonText
          primaryButtonLink
          secondaryButtonText
          secondaryButtonLink
          animAltText
        }
        seo {
          title
          description
          keywords
          image {
            url
          }
        }
        pageImages {
          url
        }
        ctaBoxes {
          type
          theme
          customBackgroundColor
          heading {
            html
          }
          description {
            html
          }
          primaryButtonText
          primaryButtonLink
          secondaryButtonText
          secondaryButtonLink
          card {
            cardName
            title
            subtext
            slug
          }
        }

        newField {
          ... on SuperOps_Hero {
            tag
            heading {
              text
              html
            }
            content {
              text
            }
            heroImage {
              url
            }
          }
          ... on SuperOps_Block {
            blockContent {
              html
            }
            blockLink {
              html
            }
            blockImage {
              url
            }
            linkName
            linkSlug
          }
          ... on SuperOps_Card {
            cardName
            heading2: heading
            title
            text
            content2: content {
              text
              html
            }
            image {
              url
            }
          }
          ... on SuperOps_FaqComponent {
            faqs {
              question
              page
              linkText
              description
            }
          }
        }
      }
      peopleList(
        orderBy: createdAt_DESC
        where: { page: "Press Room" }
        first: 3
      ) {
        isFeatured
        position
        order
        company
        name
        title
        picture {
          url
        }
        linkedInUrl
        order
        thumbnail {
          url
        }
      }
    }
  }
`
