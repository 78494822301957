import { css } from "@emotion/react"
import React, { useState } from "react"
import SwiperCore, { Autoplay, EffectCoverflow } from "swiper/core"
import "swiper/css"
import { Swiper, SwiperSlide } from "swiper/react"
import SVGIcon from "../SVGIcon"
import "./timeline-carousel.scss"

SwiperCore.use([EffectCoverflow, Autoplay])

export default function TimelineCarousel(props) {
  const [swipe, setSwipe] = useState()
  const [currentSlide, setcurrentSlide] = useState()

  const responsive = {
    1024: {
      slidesPerView: 4,
    },
    992: {
      slidesPerView: 3,
    },
    734: {
      slidesPerView: 1.2,
    },
    200: {
      slidesPerView: 1.2,
    },
  }
  const CustomButtonGroup = () => {
    return (
      <>
        <button
          className="timeline-carousel-btn timeline-carousel-btn--prev "
          onClick={() => {
            swipe.slidePrev()
          }}
        >
          <SVGIcon
            name="mspawards/arrow"
            className="carousel-btn-arrow--left"
          />
        </button>
        <div className="time-border"></div>
        <button
          className="timeline-carousel-btn timeline-carousel-btn--next "
          onClick={() => {
            swipe.slideNext()
          }}
        >
          <SVGIcon
            name="mspawards/arrow"
            className="carousel-btn-arrow--right"
          />
        </button>
      </>
    )
  }
  const onchange = data => {
    setcurrentSlide(data)
  }
  return (
    <>
      <div className="prelative">
        <div className="time-caro-container prelative">
          <div className="swiper-container">
            <Swiper
              pagination={false}
              effect={"fade"}
              //   centeredSlides={true}
              spaceBetween={30}
              // slidesPerGroupSkip={2}
              //   slidesPerView={"auto"}
              //   loop={true}
              onSlideChange={data => {
                onchange(data.realIndex)
              }}
              onBeforeInit={swipper => setSwipe(swipper)}
              speed={750}
              breakpoints={responsive}
              //   slideToClickedSlide={true}
              fadeEffect={{
                crossFade: true,
              }}
              className="mySwiperTimeline"
            >
              {props.timelineData.faqs.map((timeline, i) => (
                <SwiperSlide>
                  <div
                    className={`single-timeline prelative d-flex align-items-end `}
                    css={css`
                      background: ${timeline.page};
                    `}
                  >
                    <div className="d-flex flex-column" style={{ gap: "24px" }}>
                      {timeline.linkText.map((date, index) => {
                        return (
                          <div
                            className={`d-flex flex-column ${index == 0 &&
                              "mt16"}`}
                          >
                            <p className="timeline-date fw-800 mb8">{date}</p>
                            <p className="timeline-desc text-gery mb0">
                              {timeline.description[index]}
                            </p>
                          </div>
                        )
                      })}
                    </div>

                    <div className="position-absolute year fw-800">
                      {timeline.question[0]}
                      <div className="dash"></div>
                      <div className="circle-wrapper">
                        <SVGIcon name="circle-double" />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
      <div className="prelative mb100 d-flex align-items-center bottom-section">
        <CustomButtonGroup />
      </div>
    </>
  )
}
